var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"c"},[_vm._m(0),_c('div',{staticClass:"c-frame"},[_c('div',{staticClass:"banList"},[_vm._m(1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bList),expression:"!bList"}],staticClass:"apreloader"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bList),expression:"bList"}],staticClass:"flexible-block"},[_c('table',{staticClass:"tb",staticStyle:{"margin-top":"10px"}},[_vm._m(2),_vm._m(3),_c('tbody',{staticStyle:{"margin-top":"10px"}},_vm._l((_vm.bList),function(operation){return _c('tr',[_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(_vm.datetimeNormalizer(operation.created_at))+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.type.toUpperCase())+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.server_name)+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.player)+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.moderator)+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.expire === 0 ? 'Вечно' : _vm.datetimeNormalizer(operation.expire_at))+" ")]),_c('td',{staticClass:"info-table-td"},[_vm._v(" "+_vm._s(operation.reason)+" ")])])}),0)]),(_vm.pagesCount !== null && _vm.pagesCount !== 1)?_c('div',{staticClass:"info-table-paginator"},[_c('ul',_vm._l((_vm.pagesCount),function(pageNumber){return (Math.abs(pageNumber - _vm.currentPage) < 3 || pageNumber === _vm.pagesCount || pageNumber === 1)?_c('li',[_c('span',{class:{current: _vm.currentPage === pageNumber,
                        last: (pageNumber === _vm.pagesCount && Math.abs(pageNumber - _vm.currentPage) > 3),
                         first:(pageNumber === 1 && Math.abs(pageNumber - _vm.currentPage) > 3)},on:{"click":function($event){return _vm.setPage(pageNumber)}}},[_vm._v(_vm._s(pageNumber))])]):_vm._e()}),0)]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-info-block"},[_c('h1',{staticClass:"c-title"},[_vm._v("Банлист")]),_c('p',{staticClass:"c-info-text"},[_vm._v(" Здесь выводится информация о действующих наказаниях игроков, нарушающих правила. "),_c('br'),_vm._v("Соблюдайте правила, чтобы не попасть в этот список. И помните, что репутацию иногда очень сложно отмыть. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexible-block"},[_c('div',{staticClass:"c-row-actions-container",staticStyle:{"margin-top":"-20px"}},[_c('div',{staticClass:"c-row-actions"},[_c('ul',[_c('li',[_vm._v(" Активные наказания ")])])])]),_c('div',{staticClass:"pretty-border"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col'),_c('col'),_c('col'),_c('col'),_c('col'),_c('col'),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticStyle:{"margin-bottom":"10px","border-top":"1px solid rgba(128, 128, 128, 0.29)"}},[_c('tr',[_c('th',{staticClass:"info-table-th"},[_vm._v("Дата")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Наказание")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Сервер")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Игрок")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Модератор")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Срок действия")]),_c('th',{staticClass:"info-table-th"},[_vm._v("Причина")])])])
}]

export { render, staticRenderFns }