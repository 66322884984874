export const TimeFormatterMixin = {
    methods: {
        datetimeNormalizer(UNIX_timestamp, named=false) {
            let date = new Date(UNIX_timestamp)
            let months = ['Янв', 'Фев', 'Мрт', 'Апр', 'Мая', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
            let year = date.getFullYear()
            let month = (date.getMonth() + 1) < 10 ? '0' + `${date.getMonth() + 1}` : date.getMonth() + 1 //months from 1-12
            let space = '.'
            let day = date.getDate() < 10 ? '0' + `${date.getDate()}` : date.getDate()
            if(named){
                month = months[date.getMonth()]
                space = ' '
                day = date.getDate()
            }
            let hours = date.getHours() < 10 ? '0' + `${date.getHours()}` : date.getHours()
            let minutes = date.getMinutes() < 10 ? '0' + `${date.getMinutes()}` : date.getMinutes()
            return day + space + month + space + year + ' ' + hours + ':' + minutes;
        },
    }
}